<template>
  <!-- 新通证申购统计 -->
  <div>
    <!-- 表格区 -->
    <div style="padding: 20px">
      <el-table border="">
        <el-table-column label="序号" align="center"></el-table-column>
        <el-table-column label="数字通证" align="center"></el-table-column>
        <el-table-column label="公司名" align="center"></el-table-column>
        <el-table-column label="发行总量" align="center"></el-table-column>
        <el-table-column label="申购数量" align="center"></el-table-column>
        <el-table-column label="申购价格" align="center"></el-table-column>
        <el-table-column label="申购人数" align="center"></el-table-column>
        <el-table-column label="中签人数" align="center"></el-table-column>
        <el-table-column label="全额扣款人数" align="center"></el-table-column>
        <el-table-column label="部分扣款人数" align="center"></el-table-column>
        <el-table-column label="扣款总额" align="center"></el-table-column>
        <el-table-column label="操作" align="center"></el-table-column>
      </el-table>
    </div>

    <!-- 底部分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[10, 15, 20, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 23,
      currentPage4: 1,
    };
  },
  methods: {
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
</style>
